var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-right"},[_c('a-card',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"page-right-title"},[_c('div',{staticClass:"item-title"},[_vm._v("发票抬头")]),_c('div',{staticClass:"item-sub"},[_vm._v("可开具普通发票、增值税专用发票，请确保抬头信息准确。")])])]),_c('div',{staticClass:"form-wrap"},[_c('h4',[_vm._v("抬头信息")]),_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"企业名称"}},[_c('a-input',{attrs:{"value":_vm.invoiceInfo.invoice_title,"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"纳税人识别号"}},[_c('a-input',{attrs:{"value":_vm.invoiceInfo.taxpayer_iden_num,"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"纳税人类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'taxpayer_type',
                            {
                                initialValue:_vm.invoiceInfo.taxpayer_type,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择'
                                }
                            ]}
                        ]),expression:"[\n                            'taxpayer_type',\n                            {\n                                initialValue:invoiceInfo.taxpayer_type,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择'\n                                }\n                            ]}\n                        ]"}]},_vm._l((_vm.taxpayerTypeList),function(item,idx){return _c('a-select-option',{key:idx,attrs:{"value":item.key}},[_vm._v(" "+_vm._s(item.value)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"注册地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'register_addr',
                            {
                                initialValue:_vm.invoiceInfo.register_addr,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入注册地址'
                                }
                            ]}
                        ]),expression:"[\n                            'register_addr',\n                            {\n                                initialValue:invoiceInfo.register_addr,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入注册地址'\n                                }\n                            ]}\n                        ]"}]})],1),_c('a-form-item',{attrs:{"label":"注册电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'register_phone',
                            {
                                initialValue:_vm.invoiceInfo.register_phone,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入注册电话'
                                }
                            ]}
                        ]),expression:"[\n                            'register_phone',\n                            {\n                                initialValue:invoiceInfo.register_phone,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入注册电话'\n                                }\n                            ]}\n                        ]"}]})],1),_c('a-form-item',{attrs:{"label":"开户银行"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'bank_open_name',
                            {
                                initialValue:_vm.invoiceInfo.bank_open_name,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入开户银行'
                                }
                            ]}
                        ]),expression:"[\n                            'bank_open_name',\n                            {\n                                initialValue:invoiceInfo.bank_open_name,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入开户银行'\n                                }\n                            ]}\n                        ]"}]})],1),_c('a-form-item',{attrs:{"label":"银行账户"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'bank_account',
                            {
                                initialValue:_vm.invoiceInfo.bank_account,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入银行账户'
                                }
                            ]}
                        ]),expression:"[\n                            'bank_account',\n                            {\n                                initialValue:invoiceInfo.bank_account,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入银行账户'\n                                }\n                            ]}\n                        ]"}]})],1),_c('h4',[_vm._v("收票地址")]),_c('a-form-item',{attrs:{"label":"收票人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'title_collector',
                            {
                                initialValue:_vm.invoiceInfo.title_collector,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入收票人'
                                }
                            ]}
                        ]),expression:"[\n                            'title_collector',\n                            {\n                                initialValue:invoiceInfo.title_collector,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入收票人'\n                                }\n                            ]}\n                        ]"}]})],1),_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'phone',
                            {
                                initialValue:_vm.invoiceInfo.phone,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入手机号码'
                                }
                            ]}
                        ]),expression:"[\n                            'phone',\n                            {\n                                initialValue:invoiceInfo.phone,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入手机号码'\n                                }\n                            ]}\n                        ]"}]})],1),_c('a-form-item',{attrs:{"label":"所在地区"}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":8}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'ticket_province',
                                            {
                                                initialValue:_vm.invoiceInfo.ticket_province,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择'
                                                }
                                            ]}
                                        ]),expression:"[\n                                            'ticket_province',\n                                            {\n                                                initialValue:invoiceInfo.ticket_province,\n                                            rules: [\n                                                {\n                                                    required: true,\n                                                    message: '请选择'\n                                                }\n                                            ]}\n                                        ]"}],attrs:{"size":"large","placeholder":"请选择"},on:{"change":_vm.changeProvince}},_vm._l((_vm.provinceList),function(item){return _c('a-select-option',{key:item.regionCode,attrs:{"value":item.regionCode}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-col',{attrs:{"span":8}},[(_vm.cityList.length > 0)?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'ticket_city',
                                            {
                                                initialValue:_vm.invoiceInfo.ticket_city,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择'
                                                }
                                            ]}
                                        ]),expression:"[\n                                            'ticket_city',\n                                            {\n                                                initialValue:invoiceInfo.ticket_city,\n                                            rules: [\n                                                {\n                                                    required: true,\n                                                    message: '请选择'\n                                                }\n                                            ]}\n                                        ]"}],attrs:{"size":"large","placeholder":"请选择"},on:{"change":_vm.changeCity}},_vm._l((_vm.cityList),function(item){return _c('a-select-option',{key:item.regionCode,attrs:{"value":item.regionCode}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e()],1),_c('a-col',{attrs:{"span":8}},[(_vm.areaList.length > 0)?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'ticket_area',
                                            {
                                                initialValue:_vm.invoiceInfo.ticket_area,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择'
                                                }
                                            ]}
                                        ]),expression:"[\n                                            'ticket_area',\n                                            {\n                                                initialValue:invoiceInfo.ticket_area,\n                                            rules: [\n                                                {\n                                                    required: true,\n                                                    message: '请选择'\n                                                }\n                                            ]}\n                                        ]"}],attrs:{"size":"large","placeholder":"请选择"}},_vm._l((_vm.areaList),function(item){return _c('a-select-option',{key:item.regionCode,attrs:{"value":item.regionCode}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e()],1)],1)],1),_c('a-form-item',{attrs:{"label":"详细地址"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'detail_address',
                            {
                                initialValue:_vm.invoiceInfo.detail_address,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入详细地址'
                                }
                            ]}
                        ]),expression:"[\n                            'detail_address',\n                            {\n                                initialValue:invoiceInfo.detail_address,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入详细地址'\n                                }\n                            ]}\n                        ]"}],attrs:{"auto-size":{ minRows: 4 }}})],1),_c('a-form-item',{attrs:{"label":"电子邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                            {
                                initialValue:_vm.invoiceInfo.email,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入电子邮箱'
                                }
                            ]}
                        ]),expression:"[\n                            'email',\n                            {\n                                initialValue:invoiceInfo.email,\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入电子邮箱'\n                                }\n                            ]}\n                        ]"}]})],1),_c('a-row',[_c('a-col',{attrs:{"offset":6}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }