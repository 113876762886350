<template>
    <div class="col-right">
        <a-card>
            <div slot="title">
                <div class="page-right-title">
                    <div class="item-title">发票抬头</div>
                    <div class="item-sub">可开具普通发票、增值税专用发票，请确保抬头信息准确。</div>
                </div>
            </div>
            <div class="form-wrap">
                <h4>抬头信息</h4>
                <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :form="form" @submit="handleSubmit">
                    <a-form-item label="企业名称">
                        <a-input :value="invoiceInfo.invoice_title" disabled></a-input>
                    </a-form-item>
                    <a-form-item label="纳税人识别号">
                        <a-input :value="invoiceInfo.taxpayer_iden_num" disabled></a-input>
                    </a-form-item>
                    <a-form-item label="纳税人类型">
                        <a-select
                            v-decorator="[
                                'taxpayer_type',
                                {
                                    initialValue:invoiceInfo.taxpayer_type,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择'
                                    }
                                ]}
                            ]"
                    >
                            <a-select-option v-for="(item, idx) in taxpayerTypeList" :value="item.key" :key="idx">
                                {{item.value}}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="注册地址">
                        <a-input
                             v-decorator="[
                                'register_addr',
                                {
                                    initialValue:invoiceInfo.register_addr,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入注册地址'
                                    }
                                ]}
                            ]"

                        ></a-input>
                    </a-form-item>
                    <a-form-item label="注册电话">
                        <a-input
                             v-decorator="[
                                'register_phone',
                                {
                                    initialValue:invoiceInfo.register_phone,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入注册电话'
                                    }
                                ]}
                            ]"
                        ></a-input>
                    </a-form-item>
                    <a-form-item label="开户银行">
                        <a-input
                            v-decorator="[
                                'bank_open_name',
                                {
                                    initialValue:invoiceInfo.bank_open_name,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入开户银行'
                                    }
                                ]}
                            ]"
                        ></a-input>
                    </a-form-item>
                    <a-form-item label="银行账户">
                        <a-input
                            v-decorator="[
                                'bank_account',
                                {
                                    initialValue:invoiceInfo.bank_account,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入银行账户'
                                    }
                                ]}
                            ]"
                        ></a-input>
                    </a-form-item>
                    <h4>收票地址</h4>
                    <a-form-item label="收票人">
                        <a-input
                            v-decorator="[
                                'title_collector',
                                {
                                    initialValue:invoiceInfo.title_collector,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入收票人'
                                    }
                                ]}
                            ]"
                        ></a-input>
                    </a-form-item>
                    <a-form-item label="手机号码">
                        <a-input
                            v-decorator="[
                                'phone',
                                {
                                    initialValue:invoiceInfo.phone,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入手机号码'
                                    }
                                ]}
                            ]"
                        ></a-input>
                    </a-form-item>
                    <a-form-item label="所在地区">
                        <a-row :gutter="8">
                        <a-col :span="8">
                            <a-select size="large" placeholder="请选择" @change="changeProvince"
                                      v-decorator="[
                                                'ticket_province',
                                                {
                                                    initialValue:invoiceInfo.ticket_province,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in provinceList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="8">
                            <a-select v-if="cityList.length > 0" size="large" placeholder="请选择"
                                      @change="changeCity"
                                      v-decorator="[
                                                'ticket_city',
                                                {
                                                    initialValue:invoiceInfo.ticket_city,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in cityList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="8">
                            <a-select v-if="areaList.length > 0" size="large" placeholder="请选择"
                                      v-decorator="[
                                                'ticket_area',
                                                {
                                                    initialValue:invoiceInfo.ticket_area,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in areaList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                    </a-form-item>
                    <a-form-item label="详细地址">
                        <a-textarea
                            v-decorator="[
                                'detail_address',
                                {
                                    initialValue:invoiceInfo.detail_address,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入详细地址'
                                    }
                                ]}
                            ]"
                            :auto-size="{ minRows: 4 }"></a-textarea>
                    </a-form-item>
                     <a-form-item label="电子邮箱">
                        <a-input
                            v-decorator="[
                                'email',
                                {
                                    initialValue:invoiceInfo.email,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入电子邮箱'
                                    }
                                ]}
                            ]"
                        ></a-input>
                    </a-form-item>
                    <a-row>
                        <a-col :offset="6">
                            <a-button type="primary" html-type="submit">保存</a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
        </a-card>
    </div>
</template>

<script>
import service from "../../../utils/request";

export default {
    name: "setting-invoice",
    data(){
        return {
            invoiceInfo:{
                ticket_province:undefined,
                ticket_city:undefined,
                ticket_area:undefined
            },
            taxpayerTypeList:[
                {
                    key:1,
                    value:'一般纳税人'
                },
                {
                    key:2,
                    value:'小规模纳税人'
                }
            ],
            provinceList: [],
            cityList: [],
            areaList: [],
        }
    },
    created() {
        this.getInfo();
        this.getRegionByParent(0, 1);
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, {name: 'register'});
    },
    methods:{

        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFieldsAndScroll((err, values) => {
                console.log(values)
                if (!err) {
                    this.provinceList.forEach(item => {
                        if(item.regionCode == values.ticket_province){
                            values.province = item.name;
                        }
                    })
                    this.cityList.forEach(item => {
                        if(item.regionCode == values.ticket_city){
                            values.city = item.name;
                        }
                    })
                    this.areaList.forEach(item => {
                        if(item.regionCode == values.ticket_area){
                            values.area = item.name;
                        }
                    })
                    service.post(service.uri.invoice.modify, values).then(res => {
                        if(res.code == 200){
                            this.$message.success("保存成功");
                        }
                    });
                }
            });
        },
        changeRegion(e){
            console.log(e)
        },
        changeProvince(e) {
            this.invoiceInfo.ticket_city = undefined;
            this.invoiceInfo.ticket_area = undefined;
            this.cityList = [];
            this.areaList = [];
            this.getRegionByParent(e, 2);
        },
        changeCity(e) {
            this.invoiceInfo.ticket_area = undefined;
            this.areaList = [];
            this.getRegionByParent(e, 3);
        },
        getRegionByParent(parentCode, level) {
            service.post(service.uri.company.getRegionByParent, {parentCode: parentCode}).then(res => {
                if (res.code == 200) {
                    if (level == 1) {
                        this.provinceList = res.data.list;
                    } else if (level == 2) {
                        this.cityList = res.data.list;
                    } else if (level == 3) {
                        this.areaList = res.data.list;
                    }
                }
            });
        },
        getInfo(){
            service.post(service.uri.invoice.getInfo).then(res => {
                if(res.code == 200){

                    this.getRegionByParent(res.data.ticket_province, 2);
                    this.getRegionByParent(res.data.ticket_city, 3);
                    this.invoiceInfo = res.data;
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
.page-right-title {
    display: flex;
    align-items: center;
    .item-title {
        font-family: PingFangSC-Medium,PingFang SC;
        font-weight: 500;
    }
    .item-sub {
        font-size: 12px;
        color: #999;
        padding-left: 10px;
        flex: 1;
    }
}
.form-wrap {
    width: 600px;
}
</style>